
.custom-multi-select {
  &__control {
    background-color: var(--input-background-color) !important;
    border-radius: 5px !important;
    border: 1px solid var(--select-border-color) !important;
    box-shadow: none !important;

    &--menu-is-open {
      border-color: var(--input-background-color) !important;
    }
  }

  &__value-container {
      padding: 5px !important;
      cursor: pointer !important;
    }
    &__indicators {
        cursor: pointer !important;
    }
    &__indicator {
        color: var(--text-color-secondary)!important;

        &:hover {
            color: var(--text-color-secondary-hover)!important;
        }
    }

    &__multi-value {
        background-color: var(--background-color-secondary) !important;
    }
    &__multi-value__label {
        color: var(--button-text-color) !important;
    }
    &__multi-value__remove:hover {
        background-color: var(--text-color-secondary) !important;
        color: white !important;
    }

    &__menu {
        background-color: var(--button-background-color) !important;
    }
    &__menu-list::-webkit-scrollbar {
        width: 10px
    }
    &__menu-list::-webkit-scrollbar-thumb {
        background-color: var(--select-background-color);    /* цвет плашки */
        border-radius: 20px;       /* закругления плашки */
        border: 1px solid var(--button-border-color);  /* padding вокруг плашки */
}

    &__option {
        cursor: pointer !important;
        &--is-focused {
            background-color: var(--select-background-color) !important;
        }
    }
    &__option::-webkit-scrollbar {
        width: 5px;
    }
}

.custom-multi-select::-webkit-scrollbar {
    width: 2px;
}