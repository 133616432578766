/* Dark theme */
html[data-theme='dark'] {
    --button-text-color: #E1E3E6;
    --button-background-color: #333333;
    --button-background-color-disabled: #141414;
    --button-background-color-hover: #585858;
    --button-border-color: #555555;

    --table-border-color: #555555;

    --input-text-color: #ffffff;
    --input-placeholder-text-color: #828282;
    --input-background-color: #424242;
    --input-background-color-secondary: #222222;
    --input-border-color: #150918;

    --text-color: #e1e3e6;
    --text-color-secondary: #828282;
    --text-color-secondary-hover: #d8d8d8;

    --select-border-color: #555555;
    --select-background-color: #222222;

    --work-background-color: #333333;
    --work-background-color-sum: rgba(12, 131, 32, 0.6);

    --background-color: #141414;
    --background-color-secondary: #222222;
    --icon-color: #241627;
}

/* Light theme */
html[data-theme='light'] {
    --button-text-color: #252525;
    --button-background-color: #e3e4e6;
    --button-background-color-hover: rgb(199, 199, 199);
    --button-border-color: #dee2e6;

    --table-border-color: #dee2e6;

    --input-text-color: #252525;
    --input-placeholder-text-color: #a7a7a7;
    --input-background-color: #ffffff;
    --input-background-color-secondary: #222222;
    --input-border-color: #4e005c;

    --select-border-color: #dee2e6;
    --select-background-color: #a8a5a5;

    --text-color: #000000;
    --text-color-secondary: #828282;
    --text-color-secondary-hover: #d8d8d8;
    --input-background-color-secondary: #222222;

    --work-background-color: rgba(21, 176, 37, 0.36);
    --work-background-color-sum: rgba(11, 123, 29, 0.6);

    --background-color: #ffffff;
    --background-color-secondary: #f8f9fa;
    --icon-color: #4e005c;
}

html {
    /*user-select: none;*/
    min-height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color-secondary);
}

#root {
    background-color: var(--background-color);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 12px;               /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  background: var(--background-color-secondary);        /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: var(--button-background-color);    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 1px solid var(--button-border-color);  /* padding вокруг плашки */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
    font-weight: bold;
    color: var(--text-color);
}

p,
h1,
h2,
h3,
h4,
h5,
label,
div,
th,
td,
button {
    color: var(--text-color);
}

@media screen and (width < 765px) {
    p {
        font-size: 12px;
    }

    span {
        font-weight: bold;
        font-size: 10px;
    }

    th {
        font-size: 10px;
    }
}