/* Gallery.css */

/* Модальное окно */
.modal-gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-gallery-content {
    position: relative;
    width: 95vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-image {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain; /* Изображение сохраняет пропорции и помещается в окно */
}

.prev-button, .next-button, .close-button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 2em;
    cursor: pointer;
    padding: 0.5em;
}

.prev-button {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.next-button {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.close-button {
    top: 10px;
    right: 10px;
}

