
.work {
    align-items: center;
    margin: 4px 4px 4px 0;
    padding: 4px;
    background-color: var(--work-background-color);
    border-radius: 4px;
    font-size: 12px;
    border: none;
}
.workSum {
    background-color: var(--work-background-color-sum);
    border-radius: 2px;
    color: white;
    font-size: 10px;
    margin: 0 0 0 4px;
    padding: 2px;
}
.workSumDanger {
     background-color: rgba(199, 6, 6, 0.6);
     border-radius: 2px;
     color: white;
     font-size: 10px;
     margin: 0 0 0 4px;
     padding: 2px;
 }
.workSumUpdate {
    background-color: rgba(191, 13, 13, 0.6);
    border-radius: 2px;
    color: white;
    font-size: 10px;
    margin: 0 0 0 4px;
    padding: 2px;
}

@media screen and (width < 765px) {
    .work {
        font-size: 8px;
        display: flex;
        flex-direction: row;
        margin: 2px 2px 2px 0;
    }
    .workSum {
        font-size: 8px;
    }
}