@import './app/components/inputs/multiSelectField/react-multi-select.scss';

.App {
  text-align: center;
}

.body {
  background-color: var(--background-color);
}

.modal-content {
  background-color: var(--background-color-secondary);
  border-color: var(--button-border-color);
}

.app_table-container {
  background-color: var(--background-color-secondary);
  border-radius: 10px;
  border: 1px solid var(--button-border-color);
  padding: 10px;
}

.app_container {
  background-color: var(--background-color-secondary);
  border: 1px solid;
  border-color: var(--button-border-color);
  color: var(--button-text-color);
}

.app_logo {
  color: var(--icon-color);
}

.btn-light {
  color: var(--button-text-color);
  border: none;
  border-color: var(--button-border-color);
  background-color: var(--button-background-color);
}
.btn-light:hover {
  border-color: var(--button-border-color);
  background-color: var(--button-background-color-hover);
}
.btn-light:disabled {
  color: var(--text-color);
  border: none;
  background-color: var(--button-background-color-disabled);
}

td, th {
  border-bottom: 1px solid var(--table-border-color);
}

.app_input {
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  border-color: var(--button-border-color);
}
.app_input:focus {
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  border-color: var(--button-border-color);
  box-shadow: 0 0 0 0.2rem var(--button-border-color);
}
.app_input::placeholder {
  color: var(--input-placeholder-text-color);
}
.app_input:-webkit-autofill,
.app_input:-webkit-autofill:hover,
.app_input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color);
  -webkit-box-shadow: 0 0 0px 40rem var(--input-background-color) inset;
}

.app_input:disabled {
  color: var(--text-color);
  background-color: var(--button-background-color-disabled);
}

.app_input::-webkit-scrollbar-button {
  width: 20px;
}

.app_textarea,
.app_textarea:focus {
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  border: 1px solid var(--select-border-color);
}

input[type=checkbox]:enabled{
  cursor: pointer;
  background-color: var(--button-background-color);
  border-color: var(--select-border-color);
  box-shadow: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

text {
  line-height: 1;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 10px;
}

.container {
  display: flex;
}

.card {
  background-color: azure;
  height: 150px;
  width: 120px;
  border-radius: 5px;
  margin: 10px 5px 0 5px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0,0,0, 0.75);
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.3s;
}

.modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 20px;
  padding: 30px;
}

.modal-content {
  position: relative;
  transform: translateY(-50px);
  transition: opacity 0.3s, transform 0.3s;

}

.check-box-col {
  margin: 0;
  padding: 0;
  width: 10px;
}

.td-check-box {
  margin: 0;
  padding: 0;
  width: 10px;
}